import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import HeroesLinksList from "@/game-deadlock/components/HeroesLinksList.jsx";
import HeroPageHeader from "@/game-deadlock/components/HeroPageHeader.jsx";
import { ABILITIES } from "@/game-deadlock/constants/hero-abilities.mjs";
import { AbilityVideos, Content } from "@/game-deadlock/HeroOverview.style.jsx";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import { getHeroIdFromName } from "@/game-deadlock/utils/getHeroIdFromName.mjs";
import { heroWinrateColor } from "@/game-deadlock/utils/hero-winrate-color.mjs";
import { useHeroItemsStats } from "@/game-deadlock/utils/use-hero-items-stats.mjs";
import { useHeroStats } from "@/game-deadlock/utils/use-hero-stats.mjs";
import { useHeroesList } from "@/game-deadlock/utils/use-heroes-list.mjs";
import CharacterStatsBar from "@/shared/CharacterStatsBar.jsx";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";
import PageContainer from "@/shared/PageContainer.jsx";
import { classNames } from "@/util/class-names.mjs";
import { useRoute } from "@/util/router-hooks.mjs";

function Hero() {
  const {
    parameters: [heroIdOrName],
  } = useRoute();

  const { loading: heroesListLoading } = useHeroesList();
  const {
    loading: heroStatsLoading,
    stats: heroStats,
    heroId,
  } = useHeroStats(heroIdOrName);
  const { loading: _heroItemStatsLoading, stats: _heroItemStats } =
    useHeroItemsStats(heroIdOrName);

  const TierIcon = heroStats.tier ? getTierIcon(heroStats.tier) : null;

  return (
    <PageContainer>
      <HeroPageHeader heroIdOrName={heroIdOrName} stats={heroStats} />
      <CharacterStatsBar
        hasStats={!!heroStats.num_player_matches}
        stats={[
          {
            key: "tier",
            title: ["common:tier", "Tier"],
            value: heroStats.tier,
            format: {},
            icon: <img src={TierIcon} width="28" height="28" />,
          },
          {
            key: "winrate",
            title: ["lol:winRate", "Win Rate"],
            value: heroStats.win_rate,
            color: heroWinrateColor(heroStats.win_rate),
            format: {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
              style: "percent",
            },
          },
          {
            key: "appearance",
            title: ["common:stats.appearance", "Appearance"],
            value: heroStats.appearance_rate,
            format: {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
              style: "percent",
            },
          },
          {
            key: "kda",
            title: ["common:stats.kda", "KDA"],
            value: heroStats.avg_kda,
            format: {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          },
          {
            key: "matches",
            title: ["deadlock:stats.souldPerMin", "Souls Per Min."],
            value: heroStats.sum_net_worth / heroStats.sum_duration_m,
            format: {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            },
          },
        ]}
        loading={heroStatsLoading}
      />
      <section className={Content()}>
        <div className="main-col">
          {heroesListLoading ? (
            <Card loading style={{ height: 754 }} />
          ) : (
            <HeroAbilities heroId={heroId} />
          )}
        </div>
        <aside className="side-col">
          <HeroesLinksList heroIdOrName={heroIdOrName} />
        </aside>
      </section>
    </PageContainer>
  );
}

export default Hero;

function HeroAbilities({ heroId }) {
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);

  const [selectedAbility, setSelectedAbility] = useState(1);
  const heroAbilities = ABILITIES[heroId];
  const selectedAbilityInfo = heroAbilities?.[selectedAbility - 1];
  const selectedAbilityVideo = selectedAbilityInfo?.video;
  const videoSrc =
    selectedAbilityVideo && Assets.getHeroAbilityVideoURL(selectedAbilityVideo);

  return (
    <Card className={AbilityVideos()}>
      <div className="video-container">
        <video
          autoPlay
          muted
          loop
          playsInline
          ref={videoRef}
          width="672"
          height="378"
          src={videoSrc}
        ></video>
      </div>
      <div className="buttons">
        {[1, 2, 3, 4].map((button) => {
          const ability = heroAbilities?.[button - 1];
          const abilityIconName = ability?.icon;
          const abilityIconSrc =
            abilityIconName && Assets.getHeroAbilityImage(abilityIconName);
          return (
            <button
              key={button}
              onClick={() => setSelectedAbility(button)}
              onMouseEnter={() => setSelectedAbility(button)}
              className="button"
              {...classNames(
                "button",
                selectedAbility === button && "selected",
              )}
            >
              {abilityIconSrc ? (
                <img src={abilityIconSrc} width="48" height="48" />
              ) : (
                <div style={{ height: 48, width: 48 }} />
              )}
              <span>{button}</span>
            </button>
          );
        })}
      </div>
      {selectedAbilityInfo && (
        <div className="ability-meta">
          <div className="ability-info">
            <h4 className="type-page-header">{selectedAbilityInfo.name}</h4>
            <p
              className="type-body2 ability-description"
              dangerouslySetInnerHTML={{
                __html: selectedAbilityInfo.description,
              }}
            />
          </div>
          <div className="ability-stats-soon">
            <div className="block"></div>
            <div className="block"></div>
            <div className="block"></div>
            <span>{t("", "Hero ability Stats coming soon")}</span>
          </div>
        </div>
      )}
    </Card>
  );
}

export function meta([heroIdOrName]: [string]) {
  const heroId = getHeroIdFromName(heroIdOrName, readState.deadlock.heroes);
  const hero = readState.deadlock.heroes[heroId];
  const name = hero?.heroName;
  return {
    title: name
      ? [
          "deadlock:meta.heroPage.title",
          "{{name}}'s Deadlock Win Rate, Pick Rate, and tier list – Blitz Deadlock",
          { name },
        ]
      : [
          "deadlock:meta.tierlist.title",
          "Deadlock Tier list, Win Rates, Pick Rates, and more – Blitz Deadlock",
        ],
    description: [],
    subtitle: name
      ? [
          "deadlock:meta.heroNameSubtitle",
          "Deadlock {{name}} Abilities Stats, Item builds, Matchups, Counters, Synergies, and more for Valve's new Shooter MOBA.",
          { name },
        ]
      : [
          "deadlock:meta.heroSubtitle",
          "Deadlock Hero Abilities Stats, Item builds, Matchups, Counters, Synergies, and more for Valve's new Shooter MOBA.",
        ],
  };
}
