// This file should really be JSON from a CMS but for now its just a static file
// so we can quickly implement. Maybe one day... 🐛

// Index is the key/button of the ability (1, 2, 3, 4)
export const ABILITIES = {
  1: [
    // Infernus
    {
      video: "ability_inferno_incendiary",
      icon: "inferno_molotov_psd",
      charges: true,
      name: "Catalyst",
      description:
        'Spew napalm that <span class="highlight">slows enemy movement</span> and <span class="highlight">amplifies the damage</span> Infernus does to them.',
      upgrades: [],
    },
    {
      video: "ability_inferno_flame_dash",
      icon: "inferno_dash_psd",
      name: "Flame Dash",
      description:
        'Move forward at <span class="highlight">high speed</span> and leave a flame trail that <span class="highlight">burns enemies</span>. <br>Hold [Left Click] to dash faster',
      upgrades: [],
    },
    {
      video: "abilty_inferno_afterburn",
      icon: "inferno_deflect_psd",
      name: "Afterburn",
      description:
        'Your bullets build up to <span class="highlight">apply a burning effect on enemies</span>. Infernus\'s bullets and abilities will <span class="highlight">refresh</span> the duration.',
      upgrades: [],
    },
    {
      video: "ability_inferno_stun_bomb",
      icon: "inferno_bomb_psd",
      name: "Concussive Combustion",
      description:
        'Turns you into a living bomb that explodes after a short delay, <span class="highlight">stunning all enemies</span> in its radius.',
      upgrades: [],
    },
  ],
  2: [
    // Seven
    {
      video: "gigawatt_lightning_ball",
      icon: "giga_ball_psd",
      charges: true,
      name: "Lightning Ball",
      description:
        'Shoot a ball of lightning that travels in a straight line. Does <span class="highlight">damage to all targets in its radius</span>. Slows down when damaging enemies and stops if it hits the world.',
      upgrades: [],
    },
    {
      video: "gigawatt_static_charge",
      icon: "giga_static_psd",
      name: "Static Charge",
      description:
        'Apply a charge to a target enemy hero. After a short duration, the static charge <span class="highlight">stuns and damages</span> enemies within the radius.',
      upgrades: [],
    },
    {
      video: "gigawatt_voltage_drain",
      icon: "giga_chain_psd",
      name: "Chain Lightning",
      description:
        'Power up your weapon with a shock effect, making <span class="highlight">your bullets proc shock damage on your target</span>. This shock damage <span class="highlight">bounces to enemies</span> near your target. Occurs once per burst shot.',
      upgrades: [],
    },
    {
      video: "gigawatt_storm_cloud",
      icon: "giga_storm_psd",
      name: "Storm Cloud",
      description:
        'Channel an expanding storm cloud around you that <span class="highlight">damages all enemies</span> within its radius. Enemies do not take damage when they are out of line-of-sight.',
      upgrades: [],
    },
  ],
  3: [
    // Vindicta
    {
      video: "hornet_chain",
      icon: "pestilence_chain_psd",
      name: "Stake",
      description:
        'Throw a stake that <span class="highlight">tethers enemies</span> to the location where the stake lands. Enemy movement is restricted to the length of the tether.',
      upgrades: [],
    },
    {
      video: "hornet_flight",
      icon: "wasp_flight_psd",
      name: "Flight",
      description:
        'Leap into the air and <span class="highlight">fly</span>. While in flight your weapon deals <span class="highlight">bonus spirit damage</span>.',
      upgrades: [],
    },
    {
      video: "hornet_sting",
      icon: "hornet_crow_psd",
      name: "Crow Familiar",
      description:
        'Your crow familiar deals <span class="highlight">impact damage</span> and applies a <span class="highlight">bleed</span> that deals damage based on the <span class="highlight">target\'s current health</span>.',
      upgrades: [],
    },
    {
      video: "hornet_assassinate",
      icon: "hornet_assassinate_psd",
      name: "Assassinate",
      description:
        'Use your scoped rifle to fire a powerful shot over long distances. Deal only partial damage until fully charged after 1s of being scoped. Does <span class="highlight">bonus damage</span> to enemies with <span class="highlight">less than 50% health</span> remaining. Landing a killing blow on a player with Assassinate <span class="highlight">grants you bonus souls</span>.',
      upgrades: [],
    },
  ],
  4: [
    // Lady Geist
    {
      video: "ghost_blood_bomb",
      icon: "blood_bomb_psd",
      name: "Essence Bomb",
      description:
        '<span class="highlight">Sacrifice some of your health</span> to launch a bomb that <span class="highlight">deals damage</span> after a brief arm time.<br>Self damage type is Spirit and can be mitigated.',
      upgrades: [],
    },
    {
      video: "ghost_life_drain",
      icon: "life_drain_psd",
      name: "Life Drain",
      description:
        'Create a tether that <span class="highlight">drains enemy health over time</span> and <span class="highlight">heals you</span>. Target must be in line of sight and within max range to drain. You can shoot and use other abilities during the drain, but your movement speed is reduced by half. Press [2] to cancel.',
      upgrades: [],
    },
    {
      video: "ghost_malice",
      icon: "haze_sleep_dagger_psd",
      name: "Malice",
      description:
        '<span class="highlight">Sacrifice some of your health</span> to launch blood shards that apply a stack of Malice. Each stack <span class="highlight">slows</span> the victim and <span class="highlight">increases the damage</span> they take from you. The slow effect decreases over time.',
      upgrades: [],
    },
    {
      video: "ghost_blood_exchange",
      icon: "blood_exchange_psd",
      name: "Soul Exchange",
      description:
        '<span class="highlight">Swaps health levels</span> with an enemy target. There is a minimum health percentage that enemies can be brought down to and a minimum amount of health received based on victims current health.',
      upgrades: [],
    },
  ],
  6: [
    // Abrams
    {
      video: "atlas_siphon_life",
      icon: "bull_drain_psd",
      name: "Siphon Life",
      description:
        "Drain health from enemies in front of you while they are in the radius.",
      upgrades: [],
    },
    {
      video: "atlas_shoulder_charge",
      icon: "bull_charge_psd",
      name: "Shoulder Charge",
      description:
        '<span class="highlight">Charge forward</span>, colliding with enemies and dragging them along.  Hitting a <span class="highlight">wall</span> will <span class="highlight">Stun</span> enemies caught by Abrams. Speed increased after colliding with enemy Heroes.',
      upgrades: [],
    },
    {
      video: "atlas_beefiness",
      icon: "bull_beef_psd",
      name: "Infernal Resilience",
      description:
        '<span class="highlight">Regenerate</span> a portion of incoming damage over time.',
      upgrades: [],
    },
    {
      video: "atlas_leap",
      icon: "bull_jump_psd",
      name: "Seismic Impact",
      description:
        '<span class="highlight">Leap high into the air</span> and choose a ground location to crash into.  When you hit the ground, <span class="highlight">all enemies in the radius are damaged and stunned</span>.<br>Press [Left Click] to crash down early.',
      upgrades: [],
    },
  ],
  7: [
    // Wraith
    {
      video: "wraith_card_trick",
      icon: "wraith_card_trick_psd",
      name: "Card Trick",
      description:
        "Deal weapon damage to summon cards.  Activate to throw a card that flies towards the enemy or point under your crosshair.",
      upgrades: [],
    },
    {
      video: "wraith_project_mind",
      icon: "wraith_teleport_psd",
      name: "Project Mind",
      description:
        '<span class="highlight">Teleport</span> to the targeted location.',
      upgrades: [],
    },
    {
      video: "wraith_psychic_supercharge",
      icon: "wraith_aura_psd",
      name: "Full Auto",
      description:
        'Temporarily boosts your <span class="highlight">fire rate</span>. Nearby allies receive half the fire rate bonus.',
      upgrades: [],
    },
    {
      video: "wraith_psychic_lift",
      icon: "wraith_lift_psd",
      name: "Telekinesis",
      description:
        'Lift an enemy hero into the air, <span class="highlight">stunning</span> them for a short time. When the lift ends, the target receives Telekinesis damage.',
      upgrades: [],
    },
  ],
  8: [
    // Mcginnis
    {
      video: "ability_forge_turret",
      icon: "engineer_turret_psd",
      name: "Mini Turret",
      description:
        'Deploy a Mini Turret that <span class="highlight">automatically shoots enemies</span>. The turret expires after a limited lifetime.<br>Turrets gain 30% of McGinnis\'s max health and have 60% Spirit Resist. They deal reduced damage to troopers and objectives.',
      upgrades: [],
    },
    {
      video: "ability_forge_mobile_resupply",
      icon: "engineer_resupply_psd",
      name: "Medicinal Specter",
      description:
        'Deploy a spirit that <span class="highlight">heals nearby units</span>.',
      upgrades: [],
    },
    {
      video: "ability_forge_fissure_wall",
      icon: "engineer_fissure_2_psd",
      name: "Spectral Wall",
      description:
        'Creates a wall that <span class="highlight">divides the terrain</span> in half. On creation, the wall <span class="highlight">deals damage</span> and <span class="highlight">applies slow</span> to enemies nearby. <br>After casting, press [Left Click] or [3] to erupt the wall early.',
      upgrades: [],
    },
    {
      video: "ability_forge_rocket_barrage",
      icon: "engineer_rockets_psd",
      name: "Heavy Barrage",
      description:
        "Unleashes a volley of rockets that home in on a targeted location.",
      upgrades: [],
    },
  ],
  10: [
    // Paradox
    {
      video: "chrono_time_bomb",
      icon: "chrono_time_bomb_psd",
      name: "Pulse Grenade",
      description:
        'Throw a grenade that begins pulsing when it lands. Each pulse applies <span class="highlight">damage, movement slow, and stacking damage amplification</span> for Paradox against the victim.',
      upgrades: [],
    },
    {
      video: "chrono_chrono_sphere",
      icon: "chrono_time_wall_psd",
      name: "Time Wall",
      description:
        'Create a time warping wall that <span class="highlight">stops time</span> for all <span class="highlight">enemy projectiles and bullets</span> that touch it.  Enemies that touch the wall will take <span class="highlight">damage as a percentage of their max health</span> and be briefly slowed.',
      upgrades: [],
    },
    {
      video: "chrono_time_bank",
      icon: "duo_attack_psd",
      name: "Kinetic Carbine",
      description:
        'Start charging your weapon and gain <span class="highlight">increased movement speed</span> once it\'s fully charged. Your next shot will release the energy, dealing <span class="highlight">spirit damage</span> and applying a <span class="highlight">time stop</span> to the enemy hit. The damage dealt is an amplification of your current weapon damage. <br><br>You can slow time on yourself by pressing [Right Click] while an empowered shot is available.',
      upgrades: [],
    },
    {
      video: "chrono_chrono_swap",
      icon: "chrono_swap_psd",
      name: "Paradoxical Swap",
      description:
        'Fire a projectile that <span class="highlight">swaps your position with the target enemy hero.</span> While the effect occurs, you gain <span class="highlight">spirit lifesteal</span> and the enemy <span class="highlight">takes damage over time</span>.',
      upgrades: [],
    },
  ],
  11: [
    // Dynamo
    {
      video: "dynamo_sumo_stomp",
      icon: "sumo_stomp_psd",
      name: "Kinetic Pulse",
      description:
        "Release an energy pulse that knocks enemies up into the air.",
      upgrades: [],
    },
    {
      video: "dynamo_charged_tackle",
      icon: "sumo_quantum_psd",
      name: "Quantum Entanglement",
      description:
        'Dynamo briefly <span class="highlight">disappears into the void</span> and then reappears a short distance away. On reappearing, your weapon is <span class="highlight">reloaded</span> and has a <span class="highlight">fire rate bonus</span> for the next clip. Can be cast with [Middle Mouse Click] to also bring nearby allies and give them half fire rate bonus.',
      upgrades: [],
    },
    {
      video: "dynamo_nikuman",
      icon: "sumo_pork_bun_psd",
      name: "Rejuvenating Aurora",
      description:
        'While channelling, <span class="highlight">restore health over time</span> to you and any allies nearby.',
      upgrades: [],
    },
    {
      video: "dynamo_singularity",
      icon: "sumo_vacuum_psd",
      name: "Singularity",
      description:
        'Create a singularity in your hands that <span class="highlight">pulls in nearby enemies and damages them</span>. Once it\'s finished, enemies get knocked into the air.',
      upgrades: [],
    },
  ],
  12: [
    // Kelvin
    {
      video: "kelvin_freezing_grenade",
      icon: "freezing_grenade_psd",
      name: "Frost Grenade",
      description:
        'Throw a grenade that explodes in a cloud of freezing ice, <span class="highlight">damaging</span> and <span class="highlight">slowing</span> enemies.',
      upgrades: [],
    },
    {
      video: "kelvin_ice_path",
      icon: "ice_path_psd",
      name: "Ice Path",
      description:
        'Kelvin creates a <span class="highlight">floating trail</span> of ice and snow that gives movement bonuses to him and his allies. Kelvin gains {s:SlowResistancePercent}% slow resistance for the duration. Enemies can also walk on the floating trail. <br>Press [Left Shift] / [Left Ctrl] to travel up or down while in Ice Path.',
      upgrades: [],
    },
    {
      video: "kelvin_ice_beam",
      icon: "ice_beam_psd",
      name: "Arctic Beam",
      description:
        'Shoot freezing cold energy out in front of you, <span class="highlight">damaging targets</span> and <span class="highlight">building up movement and fire rate slow</span> against them the longer you sustain the beam on them. You have reduced movement speed while using Arctic Beam.',
      upgrades: [],
    },
    {
      video: "kelvin_frozen_shelter",
      icon: "frozen_shelter_psd",
      name: "Frozen Shelter",
      description:
        'Kelvin freezes the air around him, creating an <span class="highlight">impenetrable dome</span> around himself. While in the dome, allies rapidly <span class="highlight">regenerate health</span> and enemies are <span class="highlight">slowed</span>.',
      upgrades: [],
    },
  ],
  13: [
    // Haze
    {
      video: "ability_haze_sleep_dagger",
      icon: "haze_sleep_dagger_psd",
      name: "Sleep Dagger",
      description:
        'Throw a dagger that <span class="highlight">damages</span> and <span class="highlight">sleeps</span> the target. Sleeping targets wake up shortly after being damaged. Throwing a Dagger does not break your invisibility. Sleep Dagger does not interrupt enemies\' channeling abilities.',
      upgrades: [],
    },
    {
      video: "ability_haze_smoke_bomb",
      icon: "haze_smoke_bomb_psd",
      name: "Smoke Bomb",
      description:
        'Fade out of sight, becoming <span class="highlight">invisible</span> and gaining <span class="highlight">sprint speed</span>. Attacking removes invisibility. Close enemies can see through your invisibility.',
      upgrades: [],
    },
    {
      video: "ability_haze_fixation",
      icon: "haze_fixation_psd",
      name: "Fixation",
      description:
        'Shooting a target <span class="highlight">increases your bullet damage on that target</span>. Gain one <span class="highlight">stack</span> per bullet hit, two if the hit is a headshot.',
      upgrades: [],
    },
    {
      video: "ability_haze_bullet_flurry",
      icon: "haze_bullet_flurry_psd",
      name: "Bullet Dance",
      description:
        'Enter a flurry, <span class="highlight">firing your weapon</span> at nearby enemies with <span class="highlight">perfect accuracy</span>.  During the flurry, Haze gains a fire rate bonus and will evade some of the bullets shot at her.',
      upgrades: [],
    },
  ],
  15: [
    // Bebop
    {
      video: "bebop_uppercut",
      icon: "bebop_uppercut_psd",
      name: "Exploding Uppercut",
      description:
        'Deal <span class="highlight">Light Melee</span> damage to nearby units and send them <span class="highlight">flying back</span>, causing <span class="highlight">area damage</span> where they land. Does not damage allies.',
      upgrades: [],
    },
    {
      video: "bebop_sticky_bomb",
      icon: "bebop_sticky_bomb_psd",
      name: "Sticky Bomb",
      description:
        'Attach a Sticky Bomb to a nearby target that deals <span class="highlight">explosive damage</span> after a short fuse time. <br>Gain <span class="highlight">+2.5% bomb damage</span> for every enemy hero hit. <br>You can cast Sticky Bomb on yourself using [Middle Mouse Click].',
      upgrades: [],
    },
    {
      video: "bebop_hook",
      icon: "bebop_hook_psd",
      name: "Hook",
      description:
        'Launch out a hook that <span class="highlight">grabs and reels in</span> the first enemy or friendly hero it hits, dealing damage if the target is an enemy.<br>Target will be placed where you are facing.',
      upgrades: [],
    },
    {
      video: "bebop_ult",
      icon: "bebop_hyper_beam_psd",
      name: "Hyper Beam",
      description:
        'Charge up your laser to unleash a powerful torrent of energy that <span class="highlight">deals damage</span> and <span class="highlight">slows enemies\' movement and dashes</span>. You have slow movement and turn rate for the duration. If activated in the air, you will hover while unleashing the beam.',
      upgrades: [],
    },
  ],
  17: [
    // Grey Talon
    {
      video: "ability_orion_charged_shot",
      icon: "archer_charged_shot_psd",
      name: "Charged Shot",
      description:
        '<span class="highlight">Charge up a powerful shot</span> that pierces through enemies. Hold [1] or [Left Click] to hold the shot.',
      upgrades: [],
    },
    {
      video: "ability_orion_power_jump",
      icon: "archer_power_jump_psd",
      name: "Rain of Arrows",
      description:
        'Launches you high in the air, allowing you to glide slowly. While airborne, you gain <span class="highlight">Weapon Damage</span> and <span class="highlight">multishot on your weapon</span>. Press [SPACE] to cancel the glide.',
      upgrades: [],
    },
    {
      video: "ability_orion_immobilizing_trap",
      icon: "imobolize_trap_psd",
      name: "Immobilizing Trap",
      description:
        '<span class="highlight">Throw out a trap</span> that begins to arm itself. Once armed, the trap will trigger when an enemy enters its radius, <span class="highlight">immobilizing</span> them.',
      upgrades: [],
    },
    {
      video: "ability_orion_guided_arrow",
      icon: "archer_guided_arrow_psd",
      name: "Guided Owl",
      description:
        'After 1.5s cast time, <span class="highlight">launch a spirit owl that you control</span> and which explodes on impact, <span class="highlight">damaging and stunning</span> enemies. Hold [Left Click] to accelerate the owl.<br>Press [SPACE] to release control. Gain <span class="highlight">permanent Spirit Power</span> for each enemy hero killed with Guided Owl.',
      upgrades: [],
    },
  ],
  18: [
    // Mo & Krill
    {
      video: "mo_and_krill_scorn",
      icon: "grappler_regen_psd",
      name: "Scorn",
      description:
        'Deal <span class="highlight">damage</span> to nearby enemies and <span class="highlight">heal yourself</span> based on the damage done. Heal is stronger against enemy heroes.',
      upgrades: [],
    },
    {
      video: "mo_and_krill_burrow",
      icon: "grappler_spin_psd",
      name: "Burrow",
      description:
        'Burrow underground, <span class="highlight">moving faster,</span> and gaining <span class="highlight">spirit and bullet armor</span>. Damage from enemy heroes will reduce the speed bonus. When you jump out, knock enemies into the air and perform a spin attack that <span class="highlight">damages and slows</span>. Cooldown starts when Burrow ends.',
      upgrades: [],
    },
    {
      video: "mo_and_krill_sand_blast",
      icon: "grappler_throw_sand_psd",
      name: "Sand Blast",
      description:
        '"Spray sand that <span class="highlight">disarms</span> enemies in front of you and deals <span class="highlight">damage</span>.',
      upgrades: [],
    },
    {
      video: "mo_and_krill_combo",
      icon: "grappler_combo_psd",
      name: "Combo",
      description:
        'Hold the target in place, <span class="highlight">stunning them and dealing damage</span> during the channel. If they die during Combo, you permanently gain max health.',
      upgrades: [],
    },
  ],
  19: [
    // Shiv
    {
      video: "shiv_throw_shiv",
      icon: "shiv_toss_psd",
      name: "Serrated Knives",
      description:
        'Throw a knife that <span class="highlight">damages and slows</span> an enemy.  Each additional hit adds a <span class="highlight">stack</span> and refreshes the debuff duration, causing the damage to increase per stack.',
      upgrades: [],
    },
    {
      video: "shiv_dash",
      icon: "shiv_flash_psd",
      name: "Slice and Dice",
      description:
        'Perform a <span class="highlight">dash forward, damaging enemies along the path</span>.<br><br>Ultimate Unlock: <span class="highlight">While rage is full</span> an echo of Shiv retraces the dash path after a short delay, damaging enemies again.',
      upgrades: [],
    },
    {
      video: "shiv_shrug_it_off",
      icon: "",
      name: "Bloodletting",
      description:
        'Take only a portion of incoming damage immediately and defer the rest to be taken over time.  Activate to <span class="highlight">clear a portion of the deferred damage</span>.',
      upgrades: [],
    },
    {
      video: "shiv_killing_blow",
      icon: "shiv_killing_blow_psd",
      name: "Killing Blow",
      description:
        'Activate to leap towards an enemy hero and <span class="highlight">instantly kill</span> them if their health is <span class="highlight">below the kill threshold</span>, otherwise deal 200 damage to them.',
      upgrades: [],
    },
  ],
  20: [
    // Ivy
    {
      video: "tengu_urn",
      icon: "tengu_storm_flask_psd",
      name: "Kudzu Bomb",
      description:
        'Summon a patch of choking vines that <span class="highlight">damages</span> and <span class="highlight">slows</span> enemies in its radius.',
      upgrades: [],
    },
    {
      video: "tengu_tangotether",
      icon: "tengu_tether_psd",
      name: "Watcher's Covenant",
      description:
        'Gain bonuses and automatically connect with a nearby ally to share them.  <span class="highlight">Healing is replicated</span> among all those connected. Connection requires line of sight. Press [2] to Lock on to a target',
      upgrades: [],
    },
    {
      video: "tengu_stone_form",
      icon: "tengu_stone_form_psd",
      name: "Stone Form",
      description:
        'Turn yourself into <span class="highlight">impervious stone</span> and smash into the ground, <span class="highlight">stunning and damaging</span> enemies nearby. Heals you for a percentage of your max health. You have some air control before falling.',
      upgrades: [],
    },
    {
      video: "tengu_airlift",
      icon: "tengu_lightning_crash_psd",
      name: "Air Drop",
      description:
        'Take flight with an Ally or a bomb.  Ally gains bullet resist but cannot attack or use abilities while lifted.  Drop your ally or bomb to cause a <span class="highlight">large explosion</span> that causes <span class="highlight">movement slow</span>. Ivy and ally gain a bullet shield when flying ends. <br>Fast cast time when targetting an ally, long on self. <br>[Left Click] to accelerate flying speed. <br>[Right Click] to drop ally/bomb.',
      upgrades: [],
    },
  ],
  25: [
    // Warden
    {
      video: "warden_crowd_control",
      icon: "warden_crowd_control_psd",
      name: "Alchemical Flask",
      description:
        'Throw a flask that <span class="highlight">damages, slows,</span> and reduces the <span class="highlight">weapon damage</span> and <span class="highlight">stamina</span> of enemies it hits.',
      upgrades: [],
    },
    {
      video: "warden_high_alert",
      icon: "warden_high_alert_psd",
      name: "Willpower",
      description:
        'Gain a <span class="highlight">spirit shield</span> and <span class="highlight">bonus movement speed</span>.',
      upgrades: [],
    },
    {
      video: "warden_lock_down",
      icon: "warden_lock_down_psd",
      name: "Binding Word",
      description:
        'Gain a <span class="highlight">spirit shield</span> and <span class="highlight">bonus movement speed</span>.',
      upgrades: [],
    },
    {
      video: "warden_riot_protocol",
      icon: "warden_riot_protocol_psd",
      name: "Last Stand",
      description:
        'After charging for 2.2s, <span class="highlight">release pulses that damage enemies and heal you</span> based on the damage done.',
      upgrades: [],
    },
  ],
  27: [
    // Yamato
    {
      video: "yamato_power_slash",
      icon: "yamato_power_slash_psd",
      name: "Power Slash",
      description:
        'Channel to increase damage over 1.5 seconds, then release a <span class="highlight">fully-charged sword strike</span>.<br>Press [1] or [Left Click] to trigger the strike early, dealing partial damage.',
      upgrades: [],
    },
    {
      video: "yamato_flying_strike",
      icon: "yamato_flying_strike_psd",
      name: "Flying Strike",
      description:
        'Throw a grappling hook to <span class="highlight">reel yourself towards an enemy, damaging and slowing the target</span> when you arrive.',
      upgrades: [],
    },
    {
      video: "yamato_healing_slash",
      icon: "yamato_crimson_slash_psd",
      name: "Crimson Slash",
      description:
        'Slash enemies in front of you, <span class="highlight">damaging them and slowing their fire rate</span>. If any enemy heroes are hit, you <span class="highlight">heal</span>.',
      upgrades: [],
    },
    {
      video: "yamato_infinity_slash",
      icon: "yamato_blinding_steel_psd",
      name: "Shadow Transformation",
      description:
        'Become infused with Yamato\'s shadow soul. After an initial <span class="highlight">invincible</span> transformation, your <span class="highlight">abilities are refreshed and are 60% faster</span>, your weapon has <span class="highlight">infinite ammo</span>, and you gain <span class="highlight">damage resists</span> and <span class="highlight">immunity to negative status effects</span>. You are unable to die in this mode.',
      upgrades: [],
    },
  ],
  31: [
    // Lash
    {
      video: "lash_ground_strike",
      icon: "lash_death_slam_psd",
      name: "Ground Strike",
      description:
        'Stomp the ground beneath you, <span class="highlight">damaging enemies</span> in front of you. If you perform Ground Strike while airborne, you quickly dive towards the ground. Damage grows slower after 25m.',
      upgrades: [],
    },
    {
      video: "lash_grapple",
      icon: "lash_lash_psd",
      name: "Grapple",
      description:
        '<span class="highlight">Pull yourself through the air</span> toward a target. Using Grapple also restores <span class="highlight">one stamina charge</span> and all <span class="highlight">air jumps and dashes</span>.',
      upgrades: [],
      charges: true,
    },
    {
      video: "lash_flog",
      icon: "lash_whip_psd",
      name: "Flog",
      description:
        'Strike enemies with your whip, <span class="highlight">stealing life</span> from them.',
      upgrades: [],
    },
    {
      video: "lash_death_slam",
      icon: "lash_counter_lash_psd",
      name: "Death Slam",
      description:
        'Focus on enemies to connect whips to them. After channeling, connected enemies are <span class="highlight">lifted and stunned</span> then slammed into the ground. Your victims and any enemies in the landing zone will be <span class="highlight">damaged and slowed</span>. <br>Press [Left Click] to throw connected enemies early. Enemies that are not in line of sight or go out of range during the latch time will not be grabbed.',
      upgrades: [],
    },
  ],
  35: [
    // Viscous
    {
      video: "viscous_goo_grenade",
      icon: "viscous_goo_ball_psd",
      name: "Splatter",
      description:
        'Throw a ball of goo that <span class="highlight">deals damage</span> and leaves puddles of goo behind that apply <span class="highlight">movement slow</span> to enemies in the radius.',
      upgrades: [],
    },
    {
      video: "viscous_restorative_goo",
      icon: "viscous_restorative_goo_psd",
      name: "The Cube",
      description:
        'Encase the target in a cube of restorative goo that <span class="highlight">purges debuffs, protects from damage, and increases health regen</span>. Target is <span class="highlight">unable to take any new actions</span> while cubed. Can be used on self. Press <span class="highlight">[SPACE]</span> to escape early.',
      upgrades: [],
    },
    {
      video: "viscous_telepunch",
      icon: "melee_damage_psd",
      name: "Puddle Punch",
      description:
        'Materialize a fist in the world that <span class="highlight">punches</span> units in the area and send them flying. Enemies will be <span class="highlight">dealt damage</span>, have their dash distance reduced for a brief moment, and have their <span class="highlight">movement slowed</span>. This is considered a <span class="highlight">Light Melee</span> attack.',
      upgrades: [],
    },
    {
      video: "viscous_goo_bowling_ball",
      icon: "viscous_goo_sphere_psd",
      name: "Goo Ball",
      description:
        'Morph into a large goo ball that <span class="highlight">deals damage</span> and <span class="highlight">stuns</span> enemies on impact. The ball grants large amounts of <span class="highlight">Bullet</span> and <span class="highlight">Spirit</span> resist, bounces off walls and can <span class="highlight">double jump</span>.',
      upgrades: [],
    },
  ],
  50: [
    // Pocket
    {
      video: "pocket_barrage",
      icon: "synth_barrage_psd",
      name: "Barrage",
      description:
        'Channel to start launching projectiles that <span class="highlight">deal damage</span> and <span class="highlight">apply movement slow</span> around their impact point. Each projectile landed on a hero grants you a <span class="highlight">stacking buff that amplifies all of your damage</span>.<br>If you cast it while in the air, you\'ll <span class="highlight">float</span> and maintain any horizontal momentum you started with.',
      upgrades: [],
    },
    {
      video: "pocket_flying_cloak",
      icon: "synth_plasma_flux_psd",
      name: "Flying Cloak",
      description:
        '<span class="highlight">Escape</span> into your suitcase. When the duration ends, <span class="highlight">deal damage</span> to nearby enemies. Duration can be ended early by performing any action.',
      upgrades: [],
    },
    {
      video: "pocket_enchanters_satchel",
      icon: "synth_pulse_psd",
      name: "Enchanter's Satchel",
      description:
        '<span class="highlight">Escape</span> into your suitcase. When the duration ends, <span class="highlight">deal damage</span> to nearby enemies. Duration can be ended early by performing any action.',
      upgrades: [],
    },
    {
      video: "pocket_affliction",
      icon: "synth_affliction_psd",
      name: "Affliction",
      description:
        'Apply <span class="highlight">damage over time</span> to all enemies nearby.<br>Affliction\'s damage is non-lethal and does not apply item procs.',
      upgrades: [],
    },
  ],
  52: [
    // Mirage
    {
      video: "mirage_tornado",
      icon: "mirage_tornado_psd",
      name: "Tornado",
      description:
        'Transform yourself into a Tornado that travels forward, <span class="highlight">damaging enemies</span> and <span class="highlight">lifting them up in the air</span>. After emerging from the tornado you gain <span class="highlight">bullet evasion</span>.',
      upgrades: [],
    },
    {
      video: "mirage_fire_beetles",
      icon: "mirage_fire_beetles_psd",
      name: "Fire Scarabs",
      description:
        'Start launching fire scarabs. Each scarab can be <span class="highlight">launched separately</span>, stealing <span class="highlight">max health from enemies</span> and applying <span class="highlight">bullet resist reduction</span>. Cannot apply multiple scarabs to the same enemy. The health gain from hitting heroes is 3x as effective as non-heroes.',
      upgrades: [],
    },
    {
      video: "mirage_sand_phantom",
      icon: "mirage_sand_phantom_psd",
      name: "Djinn's Mark",
      description: `Passive: Your shots apply an <span class="highlight">increasing multiplier</span> on the target. When the multiplier on the target expires or you reach the max, it's consumed and the target suffers Spirit Damage and is briefly revealed on the map. The final damage is the base damage times the multiplier. <br/>Active: Consumer multiplier of Djinn's Mark to deal it's damage instantly.`,
      upgrades: [],
    },
    {
      video: "mirage_teleport",
      icon: "teleport_icon_png",
      name: "Traveler",
      description: `Channeled. Target an ally or visible enemy hero on the minimap, then <span class="highlight">teleport</span> to where they were when your channel started. After teleporting, you gain <span class="highlight">movement speed</span> as well as <span class="highlight">fire rate until your next reload</span>.`,
      upgrades: [],
    },
  ],
};
